<!-- 화면뷰 -->
<template>
	<div id="shopmall-apply" class="page-component">
		<div class="apply-component">
			<start-header :cmstr-aprv-sts-cd="cmstrAprvStsCd" />
			<section v-if="isShow && editView && !isRejected" class="apply-section section-form">
				<header class="section-header"><h3 class="title">입점신청</h3></header>
				<div class="round-tabs">
					<div v-if="isModify">
						<ul class="tab-list">
							<li class="tab-item">
								<a href="javascript:" class="tab-link"><span class="tab-text">입점신청</span></a>
							</li>
							<li class="tab-item is-selected">
								<a href="javascript:" class="tab-link"><span class="tab-text">신청정보 수정</span></a>
							</li>
						</ul>
					</div>
					<div v-else>
						<ul class="tab-list">
							<li class="tab-item is-selected">
								<a href="javascript:" class="tab-link"><span class="tab-text">입점신청</span></a>
							</li>
							<li class="tab-item">
								<a href="javascript:" class="tab-link"><span class="tab-text">신청정보 수정</span></a>
							</li>
						</ul>
					</div>
				</div>

				<article class="form-section">
					<header class="section-header">
						<h3 class="title">
							회사 정보
							<span class="required"> 표시는 필수 입력 사항 입니다.</span>
						</h3>
					</header>

					<div class="form-field">
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">회사명</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.bzmnNm" class="form-control" type="text" placeholder="회사명을 입력하세요" :disabled="readOnly" />
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">사업자구분</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.bzmnTypCd" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in bzmnTypCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
								</select>
							</div>
						</div>

						<div v-if="params.bzmnTypCd === '200'" class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">법인등록번호</span></label>
							</div>
							<div class="form-celltd">
								<div class="form-row">
									<input v-model="bzmnCrno" class="form-control" type="text" placeholder="법인번호를 입력하세요" name="bzmnCrno" maxlength="14" :disabled="readOnly" />
								</div>
								<div class="form-help">
									<span class="text">{{ !checkBzmnCrno ? '법인번호는 000000-0000000 형식으로 입력해주세요.' : '올바른 법인번호 형식입니다.' }}</span>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">사업자등록번호</span></label>
							</div>
							<div class="form-celltd">
								<div class="form-row">
									<input v-model="bzmnRegNo" class="form-control" type="text" placeholder="사업자등록번호를 입력하세요" maxlength="10" :disabled="readOnly" />
								</div>
								<div class="form-help">
									<span class="text">{{ bzmnRegNoValidateText }}</span>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">업태</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.bzmnBizcon" class="form-control" type="text" placeholder="업태를 입력하세요" name="bzmnBizcon" :disabled="readOnly" />
							</div>
						</div>
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">업종</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.bzmnBiztp" class="form-control" type="text" placeholder="업종을 입력하세요" name="bzmnBiztp" :disabled="readOnly" />
							</div>
						</div>

						<!-- 04.20 추가 -->
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">취급상품카테고리</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.bzmnClsCd" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in bzmnClsCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
								</select>
							</div>
						</div>
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">대표자명</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.rprsvNm" class="form-control" type="text" placeholder="대표자명을 입력하세요" name="rprsvNm" :disabled="readOnly" />
							</div>
						</div>
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">대표자생년월일</span></label>
							</div>
							<div class="form-celltd">
								<date-picker v-model="params.rprsvBrdt" class="gachi-datepicker-width" input-class="form-control" placeholder="대표자생년월일" :disabled="readOnly" />
							</div>
						</div>
						<div class="form-row w_100 mo row ta_row">
							<div class="form-cellth is-required">
								<label><span class="text">사업자 주소</span></label>
							</div>
							<div class="form-celltd">
								<div class="field-address">
									<div v-if="!isMobile" class="form-row">
										<input v-model="params.zipNo" class="form-control" type="text" placeholder="우편 번호 입력" :disabled="readOnly" />
										<button class="btn btn-primary btn-form" type="button" @click="showDaumPost" :disabled="readOnly"><span class="text">우편 번호 찾기</span></button>
									</div>

									<button v-if="isMobile" class="btn btn-primary btn-form mb-2" type="button" @click="showDaumPost" :disabled="readOnly"><span class="text">우편 번호 찾기</span></button>
									<div v-if="isMobile" class="form-row"><input v-model="params.zipNo" class="form-control" type="text" placeholder="우편 번호 입력" :disabled="readOnly" /></div>
									<div class="form-row w_50"><input v-model="params.bizplBscAddr" class="form-control" type="text" placeholder="주소 입력" :disabled="readOnly" /></div>
									<div class="form-row w_50"><input v-model="params.bizplDaddr" class="form-control" type="text" placeholder="상세 주소 입력" :disabled="readOnly" /></div>
								</div>
							</div>
						</div>
						<div class="form-row w_50 mo row ta_row">
							<div class="form-cellth is-required">
								<label
									><span class="text">세금계산서<br />이메일</span></label
								>
							</div>
							<div class="form-celltd">
								<div class="form-row mo_row">
									<input v-model="txninvEmailFirst" class="form-control" type="text" placeholder="이메일 입력" name="txninvEmail" :disabled="readOnly" />
									<div class="form-email">@</div>
									<input v-model="txninvEmailSecond" class="form-control" type="text" placeholder="이메일 입력" name="txninvEmail" :disabled="readOnly" />
									<div class="form-hyphen"></div>
									<select v-model="txninvEmailThird" class="form-control" :disabled="readOnly" @change="emailChange('txninvEmail')">
										<option value="">직접입력</option>
										<option v-for="(option, idx) in emailCdOptions" :value="option.cmmnCdValNm" :key="idx">{{ option.cmmnCdValNm }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</article>
				<article class="form-section">
					<header class="section-header"><h3 class="title">담당자 정보</h3></header>
					<div class="form-field">
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">성명</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.sentPicNm" class="form-control" type="text" placeholder="성명을 입력하세요" name="sentPicNm" :disabled="readOnly" />
							</div>
						</div>

						<div class="form-row w_50 mo row ta_row">
							<div class="form-cellth is-required">
								<label><span class="text">휴대폰</span></label>
							</div>
							<div class="form-celltd">
								<div class="form-row mo_row">
									<select v-model="sentPicMblnFirst" class="form-control" :disabled="readOnly">
										<option value="">선택</option>
										<option v-for="(option, idx) in mblhCdOptions" :value="option.cmmnCdValNm" :key="idx">{{ option.cmmnCdValNm }}</option>
									</select>
									<div class="form-hyphen">ㅡ</div>
									<input v-model="sentPicMblnSecond" class="form-control" type="text" maxlength="4" placeholder="휴대번호 입력" name="sentPicMbln" :disabled="readOnly" />
									<div class="form-hyphen">ㅡ</div>
									<input v-model="sentPicMblnThird" class="form-control" type="text" maxlength="4" placeholder="휴대번호 입력" name="sentPicMbln" :disabled="readOnly" />
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">부서명</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.sentPicDeptNm" class="form-control" type="text" placeholder="부서명을 입력하세요" name="sentPicDeptNm" :disabled="readOnly" />
							</div>
						</div>
						<div class="form-row w_50 mo row ta_row">
							<div class="form-cellth is-required">
								<label><span class="text">전화번호</span></label>
							</div>
							<div class="form-celltd">
								<div class="form-row mo_row">
									<select v-model="sentPicTelnoFirst" class="form-control" :disabled="readOnly">
										<option value="">선택</option>
										<option v-for="(option, idx) in areaCdOptions" :value="option.cmmnCdValNm" :key="idx">{{ option.cmmnCdValNm }}</option>
									</select>
									<div class="form-hyphen">ㅡ</div>
									<input v-model="sentPicTelnoSecond" class="form-control" type="text" maxlength="4" placeholder="전화번호 입력" name="sentPicTelno" :disabled="readOnly" />
									<div class="form-hyphen">ㅡ</div>
									<input v-model="sentPicTelnoThird" class="form-control" type="text" maxlength="4" placeholder="전화번호 입력" name="sentPicTelno" :disabled="readOnly" />
								</div>
							</div>
						</div>

						<div class="form-row w_50 mo row ta_row">
							<div class="form-cellth is-required">
								<label><span class="text">이메일</span></label>
							</div>
							<div class="form-celltd">
								<div class="form-row mo_row">
									<input v-model="sentPicEmailFirst" class="form-control" type="email" placeholder="이메일 입력" name="sentPicEmailFirst" :disabled="readOnly" />
									<div class="form-email">@</div>
									<input v-model="sentPicEmailSecond" class="form-control" type="email" placeholder="이메일 입력" name="sentPicEmailSecond" :disabled="readOnly" />
									<div class="form-hyphen"></div>
									&nbsp;
									<select v-model="sentPicEmailThird" class="form-control" :disabled="readOnly" @change="emailChange('sentPicEmail')">
										<option value="">직접입력</option>
										<option v-for="(option, idx) in emailCdOptions" :value="option.cmmnCdValNm" :key="idx">{{ option.cmmnCdValNm }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</article>

				<!-- 04.20 추가 -->
				<article class="form-section">
					<header class="section-header"><h3 class="title">대표상품 정보</h3></header>
					<div class="form-field">
						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">상품명</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.gdsNm" class="form-control" type="text" placeholder="상품명을 입력하세요" name="gdsNm" :disabled="readOnly" />
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">제작형태</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.mfcSpTypCd" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in mfcSpTypCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
								</select>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">제조사</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.mkr" class="form-control" type="text" maxlength="13" placeholder="제조사를 입력하세요" name="mkr" :disabled="readOnly" />
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">원산지</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.plorId" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in plorIdOptions" :value="option.plorId" :key="idx">{{ option.plorNm }}</option>
								</select>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth is-required">
								<label><span class="text">브랜드소유형태</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.brndOwnSp" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in brndOwnSpCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
								</select>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth">
								<label><span class="text">대표상품 판매가</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.rprsGdsPrc" class="form-control" type="text" placeholder="대표상품 판매가를 입력하세요" @keydown="getData" name="rprsGdsPrc" :disabled="readOnly" oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')+'원';" />
							</div>
						</div>
						<div class="form-row">
							<div class="form-cellth">
								<label><span class="text">과세/면세</span></label>
							</div>
							<div class="form-celltd">
								<select v-model="params.taxtNtaxCd" class="form-control" :disabled="readOnly">
									<option value="">선택</option>
									<option v-for="(option, idx) in taxtCdOptions" :value="option.cmmnCdVal" :key="idx">{{ option.cmmnCdValNm }}</option>
								</select>
							</div>
						</div>

						<div class="form-row">
							<div class="form-cellth">
								<label><span class="text">온라인판매URL</span></label>
							</div>
							<div class="form-celltd">
								<input v-model="params.onlSlUrl" class="form-control Url" type="text" placeholder="온라인판매URL을 입력하세요" name="onlSlUrl" :disabled="readOnly" maxLength="500" />
							</div>
						</div>
					</div>
				</article>

				<article class="form-section">
					<header class="section-header"><h3 class="title">대표상품 제출서류</h3></header>
					<div class="form-field">
						<template v-for="(docCd, idx) in docCds">
							<div class="form-row field-upload" :key="idx">
								<div class="form-cellth" :class="{ 'is-required': docCd.require || docCd.condition === params.bzmnTypCd || docCd.mfcSpTypCd === params.mfcSpTypCd || docCd.mfcSpTypCd1 === params.mfcSpTypCd || docCd.mfcSpTypCd2 === params.mfcSpTypCd || docCd.bzmnClsCd === params.bzmnClsCd || docCd.bzmnClsCd1 === params.bzmnClsCd || docCd.brndOwnSp === params.brndOwnSp }">
									<label><span class="text" v-html="docCd.text"></span></label>
								</div>

								<div class="form-celltd">
									<label :for="`docFile${docCd.code}`" class="btn btn-outline-light form-file-label">
										<input class="form-file visually-hidden" type="file" :id="`docFile${docCd.code}`" name="docCode100" accept="image/jpeg, image/png, application/pdf" @change="changeFile($event, idx)" :disabled="readOnly" />
										<span class="text">파일 찾기</span>
									</label>
									<div class="form-help">
										<span class="text">최대 첨부파일 크기는 10M 이하입니다.</span>
									</div>

									<div v-if="docCd.file != null" class="file-list-container">
										<div class="form-help">
											<strong class="text">{{ docCd.fileName }}</strong>
										</div>
										<div v-if="docCd.thumbnail" class="file-item">
											<button class="btn btn-sm btn-secondary" style="height: 30px" @click="removeFile(idx)" :disabled="readOnly">
												<span class="text">파일삭제</span>
											</button>
										</div>
										<div v-else class="file-item">
											<button class="btn btn-sm btn-secondary" style="height: 30px" @click="removeFile(idx)" :disabled="readOnly">
												<span class="text">파일삭제</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</article>

				<article class="form-section agreement_info">
					<header class="section-header"><h3 class="title">기타정보</h3></header>
					<div class="form-field">
						<div class="agree-check"></div>
						<div class="form-row w_100">
							<div class="agree-check">
								<div class="form-check">
									<input v-model="allAgreeCheck" class="form-check-input" type="checkbox" id="allAgreeCheck" name="allAgreeCheck" :disabled="readOnly" @change="allAgreeClick" />
									<label class="form-check-label" for="allAgreeCheck" style="font-size: 19px">판매 이용약관,개인정보 수집·이용 동의 동의에 모두 동의합니다.</label>
								</div>
								<div class="form-check">
									<input v-model="privacyAgree" class="form-check-input" type="checkbox" id="privacyAgreeCheck" name="privacyAgreeCheck" :disabled="readOnly" @change="agreeClick" />
									<label class="form-check-label" for="privacyAgreeCheck">개인정보 수집·이용 동의</label>
								</div>
							</div>
							<div class="privacy-agree-wrapper">
								<dl class="privacy-content">
									<dt class="header"><strong class="title">항목 (필수)</strong></dt>
									<dd class="description">
										<span class="text">회사명, 사업자등록번호, 담당자명, 담당자 이메일,담당자 휴대폰번호, 아이디, 비밀번호</span>
									</dd>
									<dt class="header"><strong class="title">수집·이용 목적</strong></dt>
									<dd class="description"><span class="text">입점신청 심사 및 업무 연락</span></dd>
									<dt class="header"><strong class="title">보유기간</strong></dt>
									<dd class="description"><span class="text">2년</span></dd>
								</dl>
							</div>
						</div>
						<dt class="header"></dt>
						<div class="form-row w_100">
							<div class="agree-check">
								<div class="form-check">
									<input v-model="termsAgree" class="form-check-input" type="checkbox" id="termsAgreeCheck" name="termsAgreeCheck" :disabled="readOnly" @change="agreeClick" />
									<label class="form-check-label" for="termsAgreeCheck">판매 이용약관</label>
								</div>
							</div>
							<div class="terms-agree-wrapper">
								<div class="terms-content">
									<dl class="terms">
										<dt class="header">제1조 [목적]</dt>
										<dd class="description"><p class="text">본 약관은 ㈜한국중소벤처기업유통원(이하 “회사”라고 합니다)이메일형식이 운영하는 웹사이트에 판매자로 가입하여 사이트에서 판매자에게 제공하는 전자상거래 관련 서비스와 기타 서비스(이하 서비스라 합니다)를 이용하는 자 간의 권리, 의무를 확정하고 이를 이행하여 상호 발전을 도모하는 것을 목적으로 합니다.</p></dd>
										<dt class="header"></dt>
										<dd class="description"><p class="text">본 약관에서 사용되는 용어의 정의는 본 약관에서 별도로 규정하는 경우를 제외하고 가치삽시다 구매이용약관 제2조를 따릅니다.</p></dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</article>

				<article v-if="cmstrAprvStsCd === '400'" class="form-section">
					<header class="section-header"><h3 class="title">반려사유</h3></header>
					<div class="form-field">
						<div class="form-row">
							<div class="form-celltd">
								<div class="form-help">
									<span class="text">{{ rjctRsn }}</span>
								</div>
							</div>
						</div>
					</div>
				</article>

				<div v-if="isModify" class="page-buttons flex-center is-wide">
					<loading-button button-text="수정하기" :is-loading="isLoading" :click-func="clickSave" :button-classes="['btn', readOnly ? 'btn-default' : 'btn-primary']" :text-classes="[]" loading-size="sm"></loading-button>
				</div>
				<div v-else class="page-buttons flex-center is-wide">
					<loading-button button-text="신청하기" :is-loading="isLoading" :click-func="clickSave" :button-classes="['btn', readOnly ? 'btn-default' : 'btn-primary']" :text-classes="[]" loading-size="sm"></loading-button>
				</div>
			</section>

			<start-check v-if="isShow && !editView && !isRejected" :cmstr-aprv-sts-cd="cmstrAprvStsCd" :mut-params="setParams" />
			<start-rejected v-if="isShow && isRejected" :rjct-rsn="rjctRsn" :mut-params="showEdit" />

			<!--      <div class="page-buttons flex-center is-wide">-->
			<!--        <button class="btn btn-primary" @click="clickSave"><span class="text">신청하기</span></button>-->
			<!--      </div>-->
		</div>
	</div>
</template>
<!-- 화면뷰끝 -->
<script>
import { ACT_CHECK_DUPLICATE_BUSINESSMAN_REG_NO, ACT_GET_CMSTR_APLY_STATUS, ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_INSERT_CMSTR_APLY, ACT_INSERT_CMSTR_APLY_DOC, ACT_UPDATE_CMSTR_APLY, ACT_GET_CMSTR_APLY_PDPLOR } from '../../store/_act_consts';
import { getCheckItems, getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat } from '../../assets/js/utils';
import { mapGetters } from 'vuex';
import { MUT_SET_COMMON_CODE_ITEMS, MUT_SHOW_ADDRESS_MODAL, MUT_SHOW_ALERT } from '../../store/_mut_consts';
import DatePicker from 'vue2-datepicker';
import { checkBusinessmanCorporationNum, checkBusinessmanRegistrationNum1, checkEmail, msgAlert, validateAlert } from '../../assets/js/modules/validation';
import StartHeader from './StartHeader';
import LoadingButton from '../common/LoadingButton';
import StartCheck from './StartCheck';
import StartRejected from './StartRejected';

export default {
	name: 'Start',
	components: {
		StartHeader,
		LoadingButton,
		DatePicker,
		StartCheck,
		StartRejected,
	},
	watch: {
		bzmnRegNo() {
			const bzmnRegNo = this.bzmnRegNo;
			this.checkBzmnRegNo = checkBusinessmanRegistrationNum1(bzmnRegNo);
			if (this.checkBzmnRegNo) {
				if (this.params.bzmnRegNo === bzmnRegNo && this.params.bzmnRegNo !== undefined) {
					this.checkDpcnBzmnRegNo = true;
				} else {
					this.$store
						.dispatch(`commerce/${ACT_CHECK_DUPLICATE_BUSINESSMAN_REG_NO}`, bzmnRegNo)
						.then((res) => {
							this.checkDpcnBzmnRegNo = isSuccess(res);
						})
						.catch((e) => {
							console.error(e);
						});
				}
			}
		},
		bzmnCrno() {
			this.checkBzmnCrno = checkBusinessmanCorporationNum(this.bzmnCrno);
		},
		txninvEmail() {},
		txninvEmailFirst() {
			this.txninvEmail = this.txninvEmailFirst + '@' + this.txninvEmailSecond;
			this.checkTxninvEmail = checkEmail(this.txninvEmail);
		},
		txninvEmailSecond() {
			this.txninvEmail = this.txninvEmailFirst + '@' + this.txninvEmailSecond;
			this.checkTxninvEmail = checkEmail(this.txninvEmail);
		},

		sentPicEmailFirst() {
			this.sentPicEmail = this.sentPicEmailFirst + '@' + this.sentPicEmailSecond;
			this.checkSentPicEmail = checkEmail(this.sentPicEmail);
		},
		sentPicEmailSecond() {
			this.sentPicEmail = this.sentPicEmailFirst + '@' + this.sentPicEmailSecond;
			this.checkSentPicEmail = checkEmail(this.sentPicEmail);
		},
		// scmMngrPasswd(){
		//   this.checkScmMngrPasswd = checkPassword(this.scmMngrPasswd);
		// },
		// scmMngrId(){
		//   const scmMngrId = this.scmMngrId;
		//   this.checkScmMngrId = checkId(scmMngrId);
		//   if(this.checkScmMngrId){
		//     this.$store.dispatch(`commerce/${ACT_CHECK_DUPLICATE_SCM_ID}`, scmMngrId)
		//     .then(res => {if(res.data){this.checkDpcnScmMngrId = res.data.dpcnYn === 'N';}})
		//     .catch(e => {console.error(e);})
		//   }
		// }
	},
	data: () => ({
		isShow: false,
		editView: false,
		isRejected: false,

		isLoading: false,
		readOnly: false,
		isModify: false,
		isApply: false,

		cmstrAprvStsCd: null,
		plorIdOptionsCd: null,

		// 사업자 등록 번호
		bzmnRegNo: '',
		checkBzmnRegNo: false,
		checkDpcnBzmnRegNo: false,

		// 법인 번호
		bzmnCrno: '',
		checkBzmnCrno: false,

		// 세금 명세 이메일
		txninvEmail: '',
		txninvEmailFirst: '',
		txninvEmailSecond: '',
		txninvEmailThird: '',
		checkTxninvEmail: false,

		//이메일
		sentPicEmailFirst: '',
		sentPicEmailSecond: '',
		sentPicEmailThird: '',
		sentPicEmail: '',
		checkSentPicEmail: false,

		//전화번호
		sentPicTelnoFirst: '',
		sentPicTelnoSecond: '',
		sentPicTelnoThird: '',
		sentPicTelno: '',
		checksentPicTelno: false,

		//휴대전화
		sentPicMblnFirst: '',
		sentPicMblnSecond: '',
		sentPicMblnThird: '',
		sentPicMbln: '',
		checksentPicMbln: false,

		// // scm 관리자 비밀번호
		// scmMngrPasswd: '',
		// checkScmMngrPasswd: false,
		//
		// scmMngrId: '',
		// checkScmMngrId: false,
		// checkDpcnScmMngrId: false,

		params: {
			cmstrAplyId: -1,

			bzmnNm: '',
			bzmnTypCd: '100',
			bzmnCrno: '',
			bzmnRegNo: '',
			bzmnBizcon: '', // 업종
			bzmnBiztp: '', // 업태
			rprsvNm: '',
			rprsvBrdt: '',
			rprsvGndrCd: '',

			zipNo: '',
			bizplBscAddr: '',
			bizplDaddr: '',

			txninvEmail: '',
			bzmnClsCd: '',

			// 04.20 추가
			gdsNm: '', // 상품명
			rprsGdsPrc: '', // 대표상품 판매가
			taxtNtaxCd: '', // 과세/면세
			mfcSpTypCd: '', // 제작형태
			mkr: '', // 제조사
			plorId: '', // 원산지
			brndOwnSp: '', // 브랜드소유형태
			onlSlUrl: '', // 온라인판매URL

			sentPicNm: '',
			sentPicDeptNm: '',
			sentPicTelno: '',
			sentPicMbln: '',
			sentPicEmail: '',

			// scmMngrId: '',
			// scmMngrPasswd: '',
			sys365AgreYn: 'N',
			// privacyAgreeCheck: 'N',
			// termsAgreeCheck: 'N',
		},
		// sys365Agre: false,
		privacyAgree: false,
		allAgreeCheck: false,
		termsAgree: false,
		rjctRsn: '',

		// 사용목적이 생기면 사용함
		// ctpvNm:'',
		// sggNm:'',
		// sggNo:'',
		// require: true,
		// 04.20 수정
		docCds: [
			{ code: '100', name: '사업자등록증', text: '사업자 등록증', require: true, file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '200', name: '중소기업(소상공인)확인서', text: '중소기업(소상공인) <br>확인서', require: true, file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '300', name: '통신판매업신고증', text: '통신판매업 신고증', require: true, file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '700', name: 'PL보험증서', text: 'PL보험증서', require: true, file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '400', name: '식품품질증명서', text: '식품 카테고리 서류 <br>(식품품질증명서 등)', bzmnClsCd: '100', file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '800', name: '시험검사서', text: '시험검사서', bzmnClsCd: '100', bzmnClsCd1: '200', file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '900', name: 'OEM/ODM 계약서', text: 'OEM/ODM계약서', mfcSpTypCd: '200', mfcSpTypCd1: '300', file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '500', name: '화장품제조판매업 책임판매업등록필증', text: '화장품제조판매업<br> 책임판매업등록필증', bzmnClsCd: '200', file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '1300', name: '라이선스 계약서', text: '라이선스 계약서', brndOwnSp: '200', file: null, fileName: null, imgReader: null, thumbnail: '' },
			{ code: '600', name: '기타상품관련증명서류', text: '기타 상품 관련 <br>증명 서류', file: null, fileName: null, imgReader: null, thumbnail: '' },
			// {code: '1000',  name: '기타상품관련증명서류', text: '기타 상품 관련 <br>증명 서류', file: null, fileName: null, imgReader: null, thumbnail: ''},
			// {code: '1100',  name: '기타상품관련증명서류', text: '기타 상품 관련 <br>증명 서류', file: null, fileName: null, imgReader: null, thumbnail: ''},
			{ code: '1200', name: '상품소개서', text: '상품소개서', file: null, fileName: null, imgReader: null, thumbnail: '' },
		],
		postLoading: false,

		validateKeys: {
			100: {
				bzmnNm: '회사 명 (회사 정보)',
				bzmnTypCd: '사업자구분 (회사 정보)',
				bzmnRegNo: '사업자등록번호 (회사 정보)',
				bzmnBizcon: '업태 (회사 정보)',
				bzmnBiztp: '업종 (회사 정보)',
				bzmnClsCd: '취급상품카테고리 (회사 정보)', //추가

				rprsvNm: '대표자 명(회사 정보)',
				rprsvBrdt: '대표자 생년월일 (회사 정보)',
				// rprsvGndrCd: "대표자 성별 (회사 정보)",
				zipNo: '사업장 주소 (회사 정보)',
				txninvEmail: '세금계산서 이메일 (회사 정보)',

				sentPicNm: '성명 (담당자 정보)',
				sentPicDeptNm: '부서명 (담당자 정보)',
				sentPicTelno: '전화번호 (담당자 정보)',
				sentPicMbln: '휴대폰 (담당자 정보)',
				sentPicEmail: '이메일 (담당자 정보)',

				gdsNm: '상품명 (대표상품 정보)',
				// rprsGdsPrc:"대표상품 판매가 (대표상품 정보)",
				// taxtNtax:  "과세/면세 (대표상품 정보)",
				mfcSpTypCd: '제작형태 (대표상품 정보)',
				mkr: '제조사 (대표상품 정보)',
				// plorId:      "원산지 (대표상품 정보)",
				brndOwnSp: '브랜드소유형태 (대표상품 정보)',
				// onlSlUrl:  "온라인판매URL (대표상품 정보)",

				// scmMngrId: '아이디 (관리자 시스템 계정)',
				// scmMngrPasswd: '비밀번호 (관리자 시스템 계정)',
			},
			200: {
				bzmnNm: '회사 명 (회사 정보)',
				bzmnCrno: '법인 번호 (회사 정보)',
				bzmnRegNo: '사업자등록번호 (회사 정보)',

				bzmnBizcon: '업태 (회사 정보)',
				bzmnBiztp: '업종 (회사 정보)',
				bzmnClsCd: '취급상품카테고리 (회사 정보)',
				rprsvNm: '대표자 명(회사 정보)',
				rprsvBrdt: '대표자 생년월일 (회사 정보)',
				zipNo: '사업장 주소 (회사 정보)',
				txninvEmail: '세금계산서 이메일 (회사 정보)',

				sentPicNm: '성명 (담당자 정보)',
				sentPicDeptNm: '부서명 (담당자 정보)',
				sentPicTelno: '전화번호 (담당자 정보)',
				sentPicMbln: '휴대폰 (담당자 정보)',
				sentPicEmail: '이메일 (담당자 정보)',

				gdsNm: '상품명 (대표상품 정보)',
				// rprsGdsPrc:"대표상품 판매가 (대표상품 정보)",
				// taxtNtax:  "과세/면세 (대표상품 정보)",
				mfcSpTypCd: '제작형태 (대표상품 정보)',
				mkr: '제조사 (대표상품 정보)',
				// plorId:      "원산지 (대표상품 정보)",
				brndOwnSp: '브랜드소유형태 (대표상품 정보)',
				// onlSlUrl:  "온라인판매URL (대표상품 정보)",

				// scmMngrId: '아이디 (관리자 시스템 계정)',
				// scmMngrPasswd: '비밀번호 (관리자 시스템 계정)',
			},
			docCommon: [
				{ code: '100', order: 0, text: '사업자 등록증' },
				{ code: '200', order: 1, text: '중소기업(소상공인) 확인서' },
				{ code: '300', order: 2, text: '통신 판매업 신고증' },
				{ code: '700', order: 3, text: 'PL보험증서' },
			],
			doc100: [
				{ code: '400', order: 4, text: '식품품질증명서' },
				{ code: '800', order: 5, text: '시험검사서' },
			],
			doc200: [
				{ code: '500', order: 7, text: '화장품제조판매업책임판매업등록필증' },
				{ code: '800', order: 5, text: '시험검사서' },
			],
			mkg200: [{ code: '900', order: 6, text: 'OEM/ODM 계약서' }],
			mkg300: [{ code: '900', order: 6, text: 'OEM/ODM 계약서' }],
			brnd200: [{ code: '1300', order: 8, text: '라이선스 계약서' }],
		},
	}),
	computed: {
		...mapGetters('auth', ['session']),
		...mapGetters('common', ['isMobile', 'commonCode']),
		bzmnTypCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'BZMN_TYP_CD');
		},
		gndrCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'GNDR_CD');
		},
		bzmnClsCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'BZMN_CLS_CD');
		},
		taxtCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'TAXT_CD');
		},
		mfcSpTypCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'MFC_SP_TYP_CD');
		},
		plorIdOptions() {
			return this.plorIdOptionsCd;
		},
		emailCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'EMAIL_CD');
		},
		brndOwnSpCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'BRND_OWN_SP_CD');
		},
		areaCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'AREA_CD');
		},
		mblhCdOptions() {
			return this.commonCode.filter((x) => x.cmmnCd === 'MBLH_CD');
		},
		scmIdValidateText() {
			if (this.checkScmMngrId && this.checkDpcnScmMngrId) {
				return '상품 등록 및 관리를 위해 사용될 계정입니다.(사용할 수 있는 ID입니다.)';
			}
			// 중복된 계정
			else if (this.checkScmMngrId && !this.checkDpcnScmMngrId) {
				return '상품 등록 및 관리를 위해 사용될 계정입니다.(이미 존재하는 ID 입니다.)';
			}
			return '상품 등록 및 관리를 위해 사용될 계정입니다.(ID는 영어 소문자,숫자 4-12자리 로 구성되어야 합니다.)';
		},
		bzmnRegNoValidateText() {
			if (this.readOnly) return '';
			if (!this.checkBzmnRegNo) {
				return '사업자등록번호는 0000000000 형식으로 입력해주세요.';
			} else if (!this.checkDpcnBzmnRegNo) {
				return '이미 등록된 사업자번호 입니다.';
			} else {
				return '사용할 수 있는 사업자번호 입니다.';
			}
		},
	},
	created() {
		this.check();
		this.getCmstrAplyStatus();
		if (this.commonCode.length === 0) this.getCommonCdList();
		this.setFileReader();
		this.getplorCdList();
	},
	methods: {
		getData(value) {
			if (value.keyCode === 8) {
				this.params.rprsGdsPrc = this.params.rprsGdsPrc.substring(0, this.params.rprsGdsPrc.length - 1);
			}
		},
		showEdit() {
			this.isRejected = false;
		},
		setParams(item) {
			setParams(this.params, item);
			// this.params.rprsvBrdt = timestampToDateFormat(this.params.rprsvBrdt, 'yyyy-MM-dd');
			if (item.rprsGdsPrc !== null) {
				this.params.rprsGdsPrc = item.rprsGdsPrc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
			}
			this.params.rprsvBrdt = new Date(this.params.rprsvBrdt);
			// 사업자 등록 번호
			this.bzmnRegNo = item.bzmnRegNo;
			this.bzmnCrno = item.bzmnCrno;
			this.txninvEmail = item.txninvEmail;

			this.sentPicEmailSecond = item.sentPicEmail.substring(item.sentPicEmail.indexOf('@') + 1, item.sentPicEmail.length);
			this.sentPicEmailFirst = item.sentPicEmail.substring(0, item.sentPicEmail.indexOf('@'));
			this.sentPicEmail = item.sentPicEmail;

			this.txninvEmailSecond = item.txninvEmail.substring(item.txninvEmail.indexOf('@') + 1, item.txninvEmail.length);
			this.txninvEmailFirst = item.txninvEmail.substring(0, item.txninvEmail.indexOf('@'));
			this.txninvEmail = item.txninvEmail;

			this.sentPicTelnoThird = item.sentPicTelno.substring(item.sentPicTelno.length - 4, item.sentPicTelno.length);
			this.sentPicTelnoSecond = item.sentPicTelno.substring(item.sentPicTelno.length - 8, item.sentPicTelno.length - 4);
			this.sentPicTelnoFirst = item.sentPicTelno.substring(0, item.sentPicTelno.length - 8);
			this.sentPicTelno = item.sentPicTelno;

			this.sentPicMblnThird = item.sentPicMbln.substring(item.sentPicMbln.length - 4, item.sentPicMbln.length);
			this.sentPicMblnSecond = item.sentPicMbln.substring(item.sentPicMbln.length - 8, item.sentPicMbln.length - 4);
			this.sentPicMblnFirst = item.sentPicMbln.substring(0, item.sentPicMbln.length - 8);
			this.sentPicMbln = item.sentPicMbln;

			if (this.cmstrAprvStsCd === '400') {
				this.readOnly = false;
				this.rjctRsn = item.rjctRsn;
				this.isRejected = true;
			} else {
				//수정일때
				this.checkBzmnRegNo = true;
				this.checkDpcnBzmnRegNo = true;
				// this.sys365Agre = 'Y';
				this.allAgreeCheck = 'Y';
				this.privacyAgree = 'Y';
				this.termsAgree = 'Y';
				this.isModify = true;
			}

			this.editView = true;
		},
		check() {
			if (this.session.userPattnCd === undefined) {
				this.$router.push({ name: 'Main' });
				return;
			}
			if (this.session.userPattnCd === '100') {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '관리자는 입점신청이 불가능 합니다.' });
				this.$router.push({ name: 'Main' });
				return;
			}
			if (this.session.userPattnCd === '300') {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '인플루언서는 입점신청이 불가능 합니다.' });
				this.$router.push({ name: 'Main' });
				return;
			}
			if (!(!this.session.supp && this.session.userPattnCd === '200')) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이미 입점신청을 완료하였습니다.' });
				this.$router.push({ name: 'Main' });
				return;
			}
		},
		getCmstrAplyStatus() {
			this.$store.dispatch(`commerce/${ACT_GET_CMSTR_APLY_STATUS}`).then((res) => {
				if (lengthCheck(res)) {
					const item = getItem(res);
					this.cmstrAprvStsCd = item.cmstrAprvStsCd;
					this.isShow = true;
				} else {
					// 신규등록
					this.isShow = true;
					this.editView = true;
					this.readOnly = false;
				}
			});
		},

		setFileReader() {
			for (let i = 0; i < this.docCds.length; i++) {
				this.docCds[i].imgReader = new FileReader();
				this.docCds[i].imgReader.onload = (event) => {
					this.docCds[i].thumbnail = event.target.result;
				};
			}
		},
		getCommonCdList() {
			this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then((res) => {
				this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
			});
		},
		getplorCdList() {
			this.$store.dispatch(`commerce/${ACT_GET_CMSTR_APLY_PDPLOR}`).then((res) => {
				const item = getItem(res);
				this.plorIdOptionsCd = item;
			});
		},

		showDaumPost() {
			if (this.postLoading) return;
			this.postLoading = true;
			this.$store.commit(`common/${MUT_SHOW_ADDRESS_MODAL}`, {
				size: 'md',
				okFunc: (a) => {
					this.params.zipNo = a.zonecode;
					this.params.bizplBscAddr = a.address;

					this.postLoading = false;
				},
				cancelFunc: () => {
					this.postLoading = false;
				},
			});
		},
		changeFile(e, idx) {
			if (e.target.files && e.target.files.length > 0) {
				const file = e.target.files[0];
				const isImg = file.type === 'image/png' || file.type === 'image/jpeg';
				const isPdf = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

				if (!isImg && !isPdf) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: '업로드 파일은 jpeg/png, image/jpeg<br>또는 pdf 형식만 가능합니다.',
						html: true,
					});
					return;
				}

				this.docCds[idx].file = file;
				this.docCds[idx].fileName = file.name;

				if (isImg) {
					this.docCds[idx].imgReader.readAsDataURL(file);
				}
				e.target.value = '';
				if (this.docCds[idx].name === '기타상품관련증명서류' && this.docCds[idx + 1].name !== '기타상품관련증명서류' && this.docCds[idx].code !== '1100') {
					if (this.docCds[idx + 1].name !== '기타상품관련증명서류') this.docCds.splice(idx + 1, 0, { code: ((idx + 1) * 100).toString(), name: '기타상품관련증명서류', text: '기타 상품 관련 <br>증명 서류', file: null, fileName: null, imgReader: null, thumbnail: '' });
					for (let i = 0; i < this.docCds.length; i++) {
						this.docCds[i].imgReader = new FileReader();
						this.docCds[i].imgReader.onload = (event) => {
							this.docCds[i].thumbnail = event.target.result;
						};
					}
				}
			}
		},
		removeFile(idx) {
			this.docCds[idx].fileName = null;
			this.docCds[idx].thumbnail = null;
			this.docCds[idx].file = null;
		},

		async clickSave() {
			if (this.readOnly) return;

			if (this.isLoading) return;

			this.setCheckParams();
			// input text 영역 validate
			if (validateAlert(this.validateKeys[this.params.bzmnTypCd], this.params)) return;
			if (this.checkFiles()) return;
			if (this.checkRest()) return;

			this.isLoading = true;

			try {
				const res = await this.saveCmstrAply();
				if (isSuccess(res)) {
					// console.log('res::', res)
					// this.cmstrAplyId = res.cmstrAplyId;
					this.params.cmstrAplyId = res.cmstrAplyId;
					const docRes = await this.insertDocFiles();

					if (isSuccess(docRes)) {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '입점신청이 완료되었습니다.',
							yesfunc: () => {
								this.editView = false;
							},
						});
						// this.isShow = false;
						// this.editView = false;
					}
				}

				// this.isShow = false;
				// this.editView = false;
				this.isLoading = false;
			} catch (e) {
				console.error(e);
				this.isLoading = false;
			}
		},
		setCheckParams() {
			if (this.checkBzmnRegNo && this.checkDpcnBzmnRegNo) this.params.bzmnRegNo = this.bzmnRegNo;
			if (this.checkBzmnCrno) this.params.bzmnCrno = this.bzmnCrno;
			if (this.checkTxninvEmail) this.params.txninvEmail = this.txninvEmail;

			if (this.checkSentPicEmail) this.params.sentPicEmail = this.sentPicEmail;
			this.params.sentPicTelno = this.sentPicTelnoFirst + this.sentPicTelnoSecond + this.sentPicTelnoThird;
			this.params.sentPicMbln = this.sentPicMblnFirst + this.sentPicMblnSecond + this.sentPicMblnThird;

			// if(this.checkScmMngrPasswd) this.params.scmMngrPasswd = this.scmMngrPasswd;
			// if(this.checkScmMngrId && this.checkDpcnScmMngrId) this.params.scmMngrId = this.scmMngrId;

			// this.params.sys365AgreYn = this.sys365Agre ? 'Y' : 'N';
			// this.params.termsAgreeCheck = this.termsAgree ? 'Y' : 'N';
			// this.params.privacyAgreeCheck = this.privacyAgree ? 'Y' : 'N';
		},
		checkFiles() {
			const msgs = [];
			for (let i = 0; i < this.validateKeys.docCommon.length; i++) {
				if (this.docCds[this.validateKeys.docCommon[i].order].file === null) {
					msgs.push(this.validateKeys.docCommon[i].text);
				}
			}
			const key = `doc${this.params.bzmnClsCd}`;
			if (this.validateKeys[key] !== undefined) {
				for (let i = 0; i < this.validateKeys[key].length; i++) {
					if (this.docCds[this.validateKeys[key][i].order].file === null) {
						msgs.push(this.validateKeys[key][i].text);
					}
				}
			}
			const key1 = `mkg${this.params.mfcSpTypCd}`;
			if (this.validateKeys[key1] !== undefined) {
				for (let i = 0; i < this.validateKeys[key1].length; i++) {
					if (this.docCds[this.validateKeys[key1][i].order].file === null) {
						msgs.push(this.validateKeys[key1][i].text);
					}
				}
			}
			const key2 = `brnd${this.params.brndOwnSp}`;
			if (this.validateKeys[key2] !== undefined) {
				for (let k = 0; k < this.validateKeys[key2].length; k++) {
					if (this.docCds[this.validateKeys[key2][k].order].file === null) {
						msgs.push(this.validateKeys[key2][k].text);
					}
				}
			}
			const result = msgs.length > 0;
			if (result) msgAlert('[설명]첨부 필수, <br>서류 누락시 신청이 반려 될수 있습니다.', msgs);
			return result;
		},
		checkRest() {
			let msg = '';
			let subMsg = '';
			let result = true;

			if (this.params.bzmnTypCd === '200' && !this.checkBzmnCrno) {
				msg = '법인 번호를 확인하세요.';
				subMsg = "( 법인 번호는 '000000-0000000' 형식으로 구성되어야 합니다. )";
			} else if (!this.checkBzmnRegNo) {
				msg = '사업자 등록 번호를 확인하세요.';
				subMsg = "( 사업자 등록 번호는 '0000000000' 형식으로 구성되어야 합니다. )";
			} else if (!this.checkDpcnBzmnRegNo) {
				msg = '이미 등록된 업체입니다.';
			} else if (!this.checkTxninvEmail) {
				msg = '세금계산서 이메일형식이 맞지 않습니다.';
			} else if (!this.checkSentPicEmail) {
				msg = '담당자 정보 이메일형식이 맞지 않습니다.';
			}
			// else if(!this.checkDpcnScmMngrId){
			//   msg = '관리자 시스템 계정 아이디를 확인하세요';
			//   subMsg = '( 이미 존재하는 ID 입니다. )'
			// }else if(!this.checkScmMngrPasswd){
			//   msg = '관리자 시스템 계정 비밀번호를 확인하세요.';
			//   subMsg = '( 비밀번호는 영문과 숫자를 조합하여 8 ~ 13자리로 입력해주세요. )'
			// }
			// else if(!this.sys365Agre){
			//   msg = '365 시스템 동의를 확인하세요.'
			// }else if(!this.sys365Agre){
			//   msg = '365 시스템 동의를 확인하세요.'
			// }
			else if (!this.privacyAgree) {
				msg = '개인정보 수집·이용 동의를 확인하세요.';
			} else if (!this.termsAgree) {
				msg = '판매 이용약관를 확인하세요.';
			} else {
				result = false;
			}

			if (result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					subText: subMsg,
					styleObj: { width: '480px' },
				});
			}
			return result;
		},
		saveCmstrAply() {
			this.params.rprsGdsPrc = Number(this.params.rprsGdsPrc.toString().replace(/[^0-9]/g, ''));
			return this.$store.dispatch(`commerce/${this.params.cmstrAplyId < 0 ? ACT_INSERT_CMSTR_APLY : ACT_UPDATE_CMSTR_APLY}`, this.params);
		},
		insertDocFiles() {
			const filtered = this.docCds.filter((x) => x.file !== null);
			const filteredLen = filtered.length - 1;
			for (let i = 0; i < filtered.length; i++) {
				const formData = new FormData();
				formData.append('files', filtered[i].file, filtered[i].fileName);
				formData.append('cmstrDocTypCd', new Blob([JSON.stringify({ cmstrDocTypCd: filtered[i].code, cmstrAplyId: this.params.cmstrAplyId })], { type: 'application/json' }));
				if (i < filteredLen) {
					this.$store.dispatch(`commerce/${ACT_INSERT_CMSTR_APLY_DOC}`, formData);
				} else if (i === filteredLen) {
					return this.$store.dispatch(`commerce/${ACT_INSERT_CMSTR_APLY_DOC}`, formData);
				}
			}
		},
		allAgreeClick() {
			if (this.allAgreeCheck) {
				this.privacyAgree = true;
				this.termsAgree = true;
			} else {
				this.privacyAgree = false;
				this.termsAgree = false;
			}
		},
		agreeClick() {
			if (this.privacyAgree && this.termsAgree) {
				this.allAgreeCheck = true;
			} else {
				this.allAgreeCheck = false;
			}
		},
		emailChange(email) {
			if (email === 'sentPicEmail') {
				this.sentPicEmailSecond = this.sentPicEmailThird;
			} else {
				this.txninvEmailSecond = this.txninvEmailThird;
			}
		},
	},
};
</script>
